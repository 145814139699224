import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-12 col-sm-12 col-xl" }
const _hoisted_2 = { class: "card h-100 " }
const _hoisted_3 = { class: "card-body d-flex justify-content-center text-center flex-column p-8" }
const _hoisted_4 = {
  href: "#",
  class: "text-gray-800 text-hover-primary d-flex flex-column"
}
const _hoisted_5 = { class: "symbol symbol-60px mb-6" }
const _hoisted_6 = { class: "fs-5 fw-bolder mb-2" }
const _hoisted_7 = { class: "fs-7 fw-bold text-gray-400 mt-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("a", _hoisted_4, [
          _createVNode("div", _hoisted_5, [
            _createVNode("img", {
              src: `media/svg/files/${_ctx.fileType}.svg`,
              alt: ""
            }, null, 8, ["src"])
          ]),
          _createVNode("div", _hoisted_6, _toDisplayString(_ctx.fileTitle), 1)
        ]),
        _createVNode("div", _hoisted_7, _toDisplayString(_ctx.createdAt), 1)
      ])
    ])
  ]))
}