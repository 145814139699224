
import { defineComponent } from "vue";

export default defineComponent({
  name: "file",
  props: {
    folderName: String,

    files: String
  },
  components: {}
});
